import { MDRPlanService } from "./../../services/mdr.plan.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TransCategoryService } from "src/app/services/transcategory.service";
import { TransCategoryModel } from "src/app/models/trans.category.model";
import { TransRatesModel } from "src/app/models/trans.rate";
import { MDRPlanModel } from "src/app/models/mdr.plan.model";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { Tools } from "src/app/framework/modules/tools";
import {
  SimpleMessage,
  MARSResponseErrorMsg,
} from "../../framework/modules/util.class";
import { MARSErrorResponse } from "src/app/models/mars.response.error.module";
import { MasterPricingPlanService } from "src/app/services/master.pricing.plan.service";
import {
  MasterPricingPlanModel,
  PricingPlanSlabModel,
  mppDataModel,
} from "src/app/models/master.pricing.plan.model";
import { MasterPricingPlanComponent } from "../master-pricing-plan/master-pricing-plan.component";

export class MPPSlabByMpp {
  mppId?: number;
  slabs?: Array<PricingPlanSlabModel>;
}

@Component({
  selector: "app-mdrplandetail",
  templateUrl: "./mdrplandetail.component.html",
  styleUrls: ["./mdrplandetail.component.css"],
})
export class MdrplandetailComponent implements OnInit {
  @Input()
  addPriv: boolean = false;
  @Input()
  editPriv: boolean = false;
  @Input()
  action: string = "";
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  _busy: boolean = false;
  _isChange: boolean = false;
  mdrPlanTransRates: Array<TransRatesModel>;
  _mdrPlanTransRates: Array<TransRatesModel>;
  data: MDRPlanModel;
  _data: MDRPlanModel;
  aggregator: AggregatorDistributorModel;
  _aggregator: AggregatorDistributorModel;
  //mpplist: Array<MasterPricingPlanModel>;
  mpplist: Array<mppDataModel>;
  selmpp: Array<MasterPricingPlanModel>;
  userAggregatorDistributorList: Array<AggregatorDistributorModel>;
  fetchedMppSlabList: Array<MPPSlabByMpp>;
  mppDropdownSettings = {
    singleSelection: true,
    idField: "id",
    textField: "description",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };

  allFetchedMppSlabList: Array<PricingPlanSlabModel>;
  currentSlab: Array<PricingPlanSlabModel>;
  constructor(
    public transCategoryService: TransCategoryService,
    private ngbModalService: NgbModal,
    public aggregatordistributedservice: AggregatorDistributorService,
    public tools: Tools,
    private mdrplanservice: MDRPlanService,
    private masterpricingplanservice: MasterPricingPlanService
  ) {
    this.populateMppList();
    this.loadAggregatorDistributor();
    this.onAddNew(false);
  }

  ngOnInit() {}
  async loadAggregatorDistributor() {
    this.userAggregatorDistributorList = await this.aggregatordistributedservice.loadAggregatorDistrbutorIdNameTypeOfUserSelfChild();
  }
  async onAddNew(copydummy: boolean) {
    this.data = new MDRPlanModel();
    this.data.merchantCategory = "B";
    this.aggregator = new AggregatorDistributorModel();
    this.selmpp = undefined;
    await this.newCopyTransRateCategories();
    if (copydummy) {
      this.copyInitData();
    }
  }
  async newCopyTransRateCategories() {
    const transcatlist = await this.transCategoryService.getTransCategoriesList();
    this.mdrPlanTransRates = new Array<TransRatesModel>();

    for (let i = 0; i < transcatlist.length; i++) {
      let transrate = new TransRatesModel();
      transrate.transCategory = transcatlist[i];
      transrate.fromAmount = transcatlist[i].fromAmount;
      transrate.toAmount = transcatlist[i].toAmount;
      this.mdrPlanTransRates.push(transrate);
    }
    this._mdrPlanTransRates = this.tools.copyFullObject(this.mdrPlanTransRates);
  }
  copyInitData() {
    this._data = this.tools.copyFullObject(this.data);
    this._aggregator = this.tools.copyFullObject(this.aggregator);
    this._mdrPlanTransRates = this.tools.copyFullObject(this.mdrPlanTransRates);
  }
  closeScreen() {
    this.data = new MDRPlanModel();
    this._data = undefined;
    this.onClose.emit(this._isChange);
  }
  getAggregatorList() {
    let aggreationlist: Array<AggregatorDistributorModel>;
    if (this.userAggregatorDistributorList) {
      aggreationlist = this.userAggregatorDistributorList.filter(
        (item) => item.type == "I" || item.type == "A"
      );
    }
    return aggreationlist;
  }
  isModifiedMPP() {
    const newMppId =
      this.selmpp && this.selmpp.length > 0 ? this.selmpp[0].id : undefined;
    if (newMppId != this.data.masterPricingPlanId) {
      return true;
    }
    return false;
  }
  isModified() {
    if (!this.data || !this._data) {
      return false;
    }
    if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.businessSegment != this._data.businessSegment) {
      return true;
    } else if (this.data.description != this._data.description) {
      return true;
    } else if (this.data.partnerSharing != this._data.partnerSharing) {
      return true;
    } else if (this.data.merchantCategory != this._data.merchantCategory) {
      return true;
    } else if (this.aggregator && !this._aggregator) {
      return true;
    } else if (!this.aggregator && this._aggregator) {
      return true;
    } else if (
      this.aggregator &&
      this._aggregator &&
      this.aggregator.id != this._aggregator.id
    ) {
      return true;
    } else if (this.isModifiedTransRates()) {
      return true;
    } else if (this.isModifiedMPP()) {
      return true;
    }
    return false;
  }
  isModifiedTransRates() {
    if (this.mdrPlanTransRates && !this._mdrPlanTransRates) {
      return true;
    } else if (!this.mdrPlanTransRates && this._mdrPlanTransRates) {
      return true;
    } else if (this.mdrPlanTransRates && this._mdrPlanTransRates) {
      for (let i = 0; i < this.mdrPlanTransRates.length; i++) {
        if (
          this.mdrPlanTransRates[i].mmFixed !=
          this._mdrPlanTransRates[i].mmFixed
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].mmPercent !=
          this._mdrPlanTransRates[i].mmPercent
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].mmMax != this._mdrPlanTransRates[i].mmMax
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psFixed !=
          this._mdrPlanTransRates[i].psFixed
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psPercent !=
          this._mdrPlanTransRates[i].psPercent
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psMax != this._mdrPlanTransRates[i].psMax
        ) {
          return true;
        }
      }
    }
    return false;
  }
  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "MDR Plan has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.action = "";
            this.closeScreen();
          }
        },
        (reason) => {}
      );
    } else {
      this.action = "";
      this.closeScreen();
    }
  }
  isPartnerSharing() {
    return this.data.partnerSharing == "Y";
  }
  onOffPartnerSharing() {
    this.data.partnerSharing = this.data.partnerSharing == "Y" ? "N" : "Y";
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this.action == "new") {
      isAllowSave = this.addPriv;
    } else if (this.action == "update") {
      isAllowSave = this.editPriv;
    }
    return isAllowSave;
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (!this._busy) {
      if (f.valid) {
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
      }
    }
    return isDisableSave;
  }
  disableCancel() {
    if (this._busy) {
      return true;
    }
    return !this.isModified();
  }

  onSaveClick() {
    if (this.isModified()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to save MDR Plan?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            document.body.style.cursor = "wait";
            this._busy = true;

            if (this.aggregator.id != undefined) {
              this.data.aggregator = this.aggregator;
            }
            if (this.mdrPlanTransRates != undefined) {
              this.data.transRates = this.mdrPlanTransRates;
            }
            if (this.selmpp && this.selmpp.length > 0) {
              this.data.masterPricingPlanId = this.selmpp[0].id;
            }
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            this.mdrplanservice.saveMDRPlan(this.data).subscribe(
              (data) => {
                if (data["success"]) {
                  this._isChange = true;
                  this.data = data["result"];
                  this.aggregator = this.data.aggregator;
                  //this.mdrPlanTransRates = this.data.transRates;
                  //this.getSlab();//this.getSlab();
                  this.populateMDRRates();
                  this.copyInitData();

                  this._isChange = true;
                  this._busy = false;
                    document.body.style.cursor = "default";
                    objmsg.message = "MDR Plan save successfully!";
                    objmsg.title = "Success";
                    objmsg.btnClass = "btn-success";
                    this.tools.simpleMessage(objmsg);
                 /* if (this.action == "new" || this.data.marsCode == undefined) {
                    this.action = "update";
                    this.submitToMars("S");
                    //this._busy = false;
                    //document.body.style.cursor = "default";
                  } else {
                    this._busy = false;
                    document.body.style.cursor = "default";
                    objmsg.message = "MDR Plan save successfully!";
                    objmsg.title = "Success";
                    objmsg.btnClass = "btn-success";
                    this.tools.simpleMessage(objmsg);
                  }*/
                } else {
                  document.body.style.cursor = "default";
                  if (data["message"] == "INVALIDCODE") {
                    objmsg.message = "Invalid code to save MDR!";
                  } else if (data["message"] == "NAMEALREADYEXISTS") {
                    objmsg.message = "Plan name already exists for Aggregator!";
                  } else if (data["message"] == "Error") {
                    objmsg.message = "Failed to save MDR Plan!";
                  }
                  objmsg.title = "Error";
                  objmsg.btnClass = "btn-error";
                  this.tools.simpleMessage(objmsg);
                  this._busy = false;
                }
              },
              (error) => {
                document.body.style.cursor = "default";
                objmsg.title = "Error";
                objmsg.message = "Failed to save MDR Plan!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
                this._busy = false;
              }
            );
          }
        },
        (reason) => {}
      );
    }
  }

  submitToMars(source: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();
    document.body.style.cursor = "wait";
    this._busy = true;

    this.mdrplanservice.updateToMARS(this.data).subscribe(
      (res) => {
        this._busy = false;
        document.body.style.cursor = "default";
        if (res["success"]) {
          this.data.marsCode = res["result"].marsCode;
          this.data.status = res["result"].status;
          console.log(res["result"].status);
          console.log(this.data.status);
          // this._data.marsCode = res["result"].marsCode;
          this._data.status = res["result"].status;

          objmsg.title = "Success";
          if (source == "D") {
            objmsg.message = "MDR Plan updated in MARS successfully!";
          } else {
            objmsg.message = "MDR Plan successfully saved!";
          }
          objmsg.btnClass = "btn-success";
        } else {
          const msg: String = res["message"];
          objmsg.title = "Warning";
          if (source == "D") {
            objmsg.message = "MDR Plan MARS update failed, error - " + msg;
          } else {
            objmsg.message ="MDR Plan saved successfully"
              //"MDR Plan saved successfully but MARS update failed, error - " +
              msg;
          }
          objmsg.btnClass = "btn-warning";
        }
        this.tools.simpleMessage(objmsg);
      },
      (err) => {
        this._busy = false;
        document.body.style.cursor = "default";
        objmsg.title = "Warning";
        if (source == "D") {
          objmsg.message = "MDR Plan MARS update failed, error - " + err;
        } else {
          objmsg.message = "MDR Plan saved successfully"
            //"MDR Plan saved successfully but MARS update failed, error - " +
            //err;
        }
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  getMasterPricingPlan(id: number) {
    const constobjmpp = this.mpplist.find((obj) => obj.id == id);
    let objmpp: MasterPricingPlanModel = undefined;
    if (constobjmpp) {
      objmpp = this.tools.copyFullObject(constobjmpp);
    }
    return objmpp;
  }
  getMDRPlanDetail(code: number) {
    this.mdrplanservice.getMDRPlanDetail(code).subscribe(
      (data) => {
        if (data["success"]) {
          this.data = data["result"];
          this.aggregator = this.data.aggregator;
          //this.mdrPlanTransRates = this.data.transRates;
          this.selmpp = undefined;
          if (this.data.masterPricingPlanId) {
            if (!this.selmpp) {
              this.selmpp = new Array<MasterPricingPlanModel>();
            }
            const t_mpp = this.getMasterPricingPlan(
              this.data.masterPricingPlanId
            );
            if (this.selmpp.length > 0) {
              this.selmpp[0] = t_mpp;
            } else {
              this.selmpp.push(t_mpp);
            }
          }
          //this.getSlab();
          //this.getSlab();
          this.populateMDRRates();
          this.copyInitData();
        } else {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.btnClass = "btn-error";
          if (data["message"] == "INVALIDCODE") {
            objmsg.message = "Invalid MDR Plan!";
            this.tools.simpleMessage(objmsg);
          } else if (data["message"] == "Error") {
            objmsg.message = "Failed to get MDR Plan!";
            this.tools.simpleMessage(objmsg);
          }
        }
      },
      (error) => {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Error";
        objmsg.message = "Failed to get MDR Plan!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  onCancelClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to discard the changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.data = this._data;
            this.aggregator = this._aggregator;
            this.mdrPlanTransRates = this._mdrPlanTransRates;
            this.copyInitData();
          }
        },
        (reason) => {}
      );
    }
  }

  getStatus() {
    let returnValue = "";
    switch (this.data.status) {
      case "A":
        returnValue = "Active";
        break;
      case "P":
        returnValue = "Active";
        //returnValue = "Pending for MARS update";
        break;
      case "I":
        returnValue = "Inactive";
        break;
      case "F":
        returnValue = "Active";
        //returnValue = "MARS Update failed";
        break;
    }
    return returnValue;
  }

  disableBackbutton() {
    return this._busy;
  }

  showMARSResponseError() {
    if (this.data.code) {
      this.mdrplanservice.getMDRMARSResApi(this.data.code).subscribe(
        (res) => {
          if (res["result"]) {
            const data: MARSErrorResponse = res["result"];
            let objMsg: MARSResponseErrorMsg = new MARSResponseErrorMsg();
            objMsg.forEntity = this.data.name;
            objMsg.errorMsg = data;
            this.tools.marsResponseErrorMessage(objMsg);
          } else {
            console.error(res["message"]);
          }
        },
        (err) => {
          console.error("Error to get MARS Reponse Errors");
        }
      );
    }
  }

  populateMppList() {
    this.masterpricingplanservice.getAllMppList().subscribe(
      (data) => {
        //if (data["success"]) {
          if (data) {
            //console.log(data)
            let mppdata:any=data
          this.mpplist = mppdata;
          console.log(this.mpplist)

        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get Master pricing Plan list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      },
      (err) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get Master pricing Plan list!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  onMppSelect(e) {
    const id=e.id;
    const selectedmpp=this.mpplist.filter(item => item.id ===id);
    console.log(selectedmpp);
   // this.getSlab();
  }

  onMppDeSelect(e) {
    this.getSlab();
  }
  getSlab() {
    let foundinchache = false;
    if (this.selmpp && this.selmpp.length > 0) {
      const mppid = this.selmpp[0].id;
      if (this.fetchedMppSlabList && this.fetchedMppSlabList.length > 0) {
        const tmpSelMpp = this.fetchedMppSlabList.find(
          (itm) => itm.mppId == mppid
        );
        if (tmpSelMpp) {
          foundinchache = true;
          this.currentSlab = this.tools.copyFullObject(tmpSelMpp.slabs);
          this.makeTransListInvisible();
        }
      }
      if (!foundinchache) {
        this.masterpricingplanservice
          .getMasterPricingPlanSlabs(mppid)
          .subscribe((data) => {
            if (data["success"]) {
              this.currentSlab = data["result"];
              if (!this.fetchedMppSlabList) {
                this.fetchedMppSlabList = new Array<MPPSlabByMpp>();
              }
              this.fetchedMppSlabList.push({
                mppId: mppid,
                slabs: this.tools.copyFullObject(this.currentSlab),
              });
              this.makeTransListInvisible();
            } else {
              this.currentSlab = undefined;
            }
          });
      }
    } else {
      this.currentSlab = undefined;
    }
  }

  makeTransListInvisible() {
    this.mdrPlanTransRates = this.tools.copyFullObject(this._mdrPlanTransRates);
    if (this.mdrPlanTransRates && this.mdrPlanTransRates.length > 0) {
      if (this.currentSlab && this.currentSlab.length > 0) {
        for (let i = 0; i < this.mdrPlanTransRates.length; i++) {
          if (this.mdrPlanTransRates[i].transCategory.hasChild == "N") {
            if (
              !this.currentSlab.find(
                (itm) =>
                  itm.code ==
                  this.mdrPlanTransRates[i].transCategory.marsSlabCode
              )
            ) {
              this.mdrPlanTransRates[i].transCategory.invisible = true;
            } else {
              this.mdrPlanTransRates[i].transCategory.invisible = false;
            }
          } else {
            this.mdrPlanTransRates[i].transCategory.invisible = false;
          }
        }
      }
    }
  }

  populateMDRRates() {
    //console.log("test");
    
    if (
      this.data.transRates &&
      this.data.transRates.length > 0 &&
      this.mdrPlanTransRates &&
      this.mdrPlanTransRates.length > 0
    ) {
      for (let i = 0; i < this.mdrPlanTransRates.length; i++) {
        let trate = this.data.transRates.find((itm) => {
          return (
            itm.transCategory.id == this.mdrPlanTransRates[i].transCategory.id
          );
        });
        if (trate) {
          this.mdrPlanTransRates[i].mmFixed = trate.mmFixed;
          this.mdrPlanTransRates[i].mmMax = trate.mmMax;
          this.mdrPlanTransRates[i].mmPercent = trate.mmPercent;
          this.mdrPlanTransRates[i].psFixed = trate.psFixed;
          this.mdrPlanTransRates[i].psMax = trate.psMax;
          this.mdrPlanTransRates[i].psPercent = trate.psPercent;
          this.mdrPlanTransRates[i].transCategory.invisible = false;
           // trate.transCategory.invisible;
            console.log(this.mdrPlanTransRates)
        }
      }
    }
  }
}
