import { Injectable } from "@angular/core";
import { ExportToCsv } from "export-to-csv";
import { gridColumns } from "../util/components/gridtable/gridtable.component";
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class ExporttocsvService {
  constructor() {}

  exportToCsv(
    data: any[],
    gridcol: gridColumns[],
    options: any={},
    fileName: string,
    _scope?
  ) {
    if (!_scope) {
      _scope = this;
    }
    if (data.length > 0) {
      if (!options) {
        options = {
          filename:
            fileName +
            "_" +
            formatDate(new Date(), "dd-MMM-yyyyTHH:mm:ss", "en"),
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: false,
          title: "My Awesome CSV",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        const converteddata = this.getConvertedData(data, gridcol, _scope);
        csvExporter.generateCsv(converteddata);
      }
    } else {
      alert("No data to export!");
    }
  }
  getConvertedData(json: any[], gridcol: gridColumns[], _scope) {
    let jsonarr: Array<any> = new Array<any>();
    for (let cnt = 0; cnt < json.length; cnt++) {
      let jsonitem = json[cnt];
      let jsonobj = {};
      let addItem = false;
      for (let i = 0; i < gridcol.length; i++) {
        if (
          !(
            gridcol[i].isSrCol ||
            ((gridcol[i].columnHeader == "" ||
              gridcol[i].columnHeader == undefined) &&
              gridcol[i].columnHeaderFunction == undefined)
          )
        ) {
          //if(jsonitem[gridcol[i].columnFieldName]) {
          const headertext = this.getHeader(gridcol[i], _scope);
          jsonobj[headertext] = this.getValue(jsonitem, gridcol[i],_scope);
          addItem = true;
          // }
        }
      }
      if (addItem) {
        jsonarr.push(jsonobj);
      }
    }
    return jsonarr;
  }
  getHeader(col: gridColumns, _scope) {
    let headertext: string = "";
    if (col.columnHeaderFunction) {
      headertext = col.columnHeaderFunction(_scope);
    } else {
      headertext = col.columnHeader;
    }
    return headertext;
  }
  getValue(obj: any, col: gridColumns,_scope) {
    let retvalue: string;
    let colfieldname = col.columnFieldName.split(".");
    if (col.callbackFunctionRowPrm) {
      retvalue = col.callbackFunctionRowPrm(obj,_scope,col);
    } else {
      let objColVal: any = obj;
      colfieldname.forEach((part) => {
        if (objColVal) {
          objColVal = objColVal[part];
        }
      });

      if (col.callbackFunction) {
        //retvalue= col.callbackFunction(obj[col.columnFieldName]);
        retvalue = col.callbackFunction(objColVal, col._parentscope,col);
      } else {
        retvalue = objColVal;
        //retvalue= obj[col.columnFieldName];
      }
    }
    if (retvalue == undefined) {
      retvalue = "";
    }
    return retvalue;
  }
}
