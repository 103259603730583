import { Component, OnInit, ViewChild } from "@angular/core";
import { gridColumns } from "src/app/framework/util/components/simplegrid/simplegrid.component";
import {
  GridtableComponent,
  GridPage
} from "src/app/framework/util/components/gridtable/gridtable.component";
import {
  SortClass,
  SimpleMessage
} from "../../../framework/modules/util.class";
import {
  TransactionModel,
  CardDetailModel
} from "src/app/reports/models/transaction.model";
import { TransactionService } from "src/app/reports/services/transaction.service";
import { Tools } from "src/app/framework/modules/tools";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { TopbarText } from "../../../framework/components/topbar/topbar.component";
import { Router } from "@angular/router";
import { global } from "src/app/framework/modules/global";
import { DatePipe } from "@angular/common";
import { MerchantModel } from "src/app/models/merchant.model";
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';

export class SearchFields {
  dateFrom: Date;
  dateTill: Date;
  amountFrom?: number;
  amountTo?: number;
  transactionType?: string;
  terminalId?: string;
  cardNumber?: string;
  status?: string;
  rrn?: number;
}
@Component({
  selector: "app-transreport",
  templateUrl: "./transreport.component.html",
  styleUrls: ["./transreport.component.css"]
})
export class TransreportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  @ViewChild("snav", { static: true }) sideNave: any;
  topbartext: TopbarText[] = [
    {
      key: "transactionreport",
      displayText: "Transaction Report"
    }
  ];
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "RR Number",
      columnFieldName: "rrn",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "DateTime",
      columnFieldName: "tranDate",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS
    },
    {
      columnHeader: "terminal Id",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Amount",
      columnFieldName: "amount",
      columnClasses: "c-col-pc-10 text-right pr-4",
      callbackFunction: this.formateNumberD2 // Adjusted to divide by 100
      //callbackFunction :  this.tools.formateNumberD2
    },
    {
      columnHeader: "Account",
      columnFieldName: "card",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Transaction Type",
      columnFieldName: "txnType",
      columnClasses: "c-col-pc-10",
      callbackFunction:this.transformTxnType
    },
    {
      columnHeader: "Status",
      columnFieldName: "txnStatus",
      columnClasses: "c-col-pc-15",
      callbackFunction:this.transformStatus
      //callbackFunction: this.getStatusText,
      //callClassFunction: this.statusClass
    }
  ];
  sortFields = [
    { name: "rrno", display: "RR Number" },
    { name: "otpDatetime", display: "datetime" },
    /*{ name: "userId", display: "Login Id" },*/
    { name: "amount", display: "Amount" },
    { name: "cardPanNo", display: "Account" },
    { name: "transactionType", display: "Transaction Type" },
    { name: "statusDescription", display: "status" }
  ];

  transactionTypeFilter = [
    { name: "Mobikwik", display: "Mobikwik" },
    { name: "PayUMoney", display: "PayUMoney" },
    { name: "Citrus Wallet", display: "Citrus Wallet" },
    { name: "CARD", display: "CARD" },
    { name: "Freecharge", display: "Freecharge" },
    { name: "VOID", display: "VOID" },
    { name: "CASHATPOS", display: "CASHATPOS" },
    { name: "CVOID", display: "CVOID" },
    { name: "CASH", display: "CASH" },
    { name: "CASHVOID", display: "CASHVOID" },
    { name: "Airtel Money", display: "Airtel Money" },
    { name: "CASHBACK", display: "CASHBACK" }
  ];
  searchField: SearchFields;
  sort: SortClass;
  public datalist;
  public data: TransactionModel;
  public datacarddetail: CardDetailModel;
  public datamerchant: MerchantModel;
  page: GridPage;
  _menuId: string = "";
  id: number;
  constructor(
    private transactionservice: TransactionService,
    public tools: Tools,
    private ngbModalService: NgbModal,
    private router: Router,
    private objGlobal: global,
    private auth: AuthService,
    private csvservice : ExporttocsvService
  ) {
    this.data = new TransactionModel();
    this.datacarddetail = new CardDetailModel();
    this.datamerchant = new MerchantModel();
    this.searchField = new SearchFields();
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = new Date();
    
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this.sort = new SortClass();
    //this.sort.sortDirection = "D";
    //this.sort.sortFieldName = "id";
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
    this.setTitle();
    //this.getDatalist();
  }
  setTitle() {
    
    if (this.router.url == "/main/cardtransactionreport") {
      this.topbartext = [{key: "card-transaction",displayText: "CARD TRANSACTION"}];
    } else {
      this.topbartext = [{key: "transactionreport",displayText: "TRANSACTION REPORT"}];
    }
  }
  ngOnInit() {
  //  this.getTransactionData()
  }
  transformTxnType(value: string): string {
    if (value === 'S') {
      return 'Sale';
    } else if (value === 'P') {
      return 'Preapproved Sale';
    } else if (value === 'A') {
      return 'Sale not Settled';
    } else if (value === 'D') {
      return 'Refund';
    } else if (value === 'R') {
      return 'Sale Reversal';
    } else if (value === 'C') {
      return 'Sale Settled';
    } else if (value === 'I') {
      return 'Pending';
    } else if (value === 'F') {
      return 'Failed';
    } else if (value === 'V') {
      return 'Sale Void';
    } else if (value === 'Y') {
      return 'Refund Void';
    } else if (value === 'Z') {
      return 'Refund Reversal';
    } else {
      return value;
    }
  }
  transformStatus(value: string): string {
    if (value === 'S') {
      return 'Success';
    } else if (value === 'F') {
      return 'Failed';
    } else if (value === 'E') {
      return 'Error';
    } else if (value === 'C') {
      return 'Settled';
    } else if (value === 'V') {
      return 'Void';
    }else {
      return value;
    }
  }
  formateNumberD2(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    // Divide by 100 and format to 2 decimal places
    const formattedValue = (value / 100).toFixed(2);
    return formattedValue;
  }
  dataTimeValueST = (val: Date) => {
    if (val) {
      const datePortion = val.toISOString().substring(0, 10);
      const timePortion = "00:00:00";
      return `${datePortion}T${timePortion}`;
    }
  }
  dataTimeValueET = (val: Date) => {
    if (val) {
      const datePortion = val.toISOString().substring(0, 10);
      const timePortion = "23:59:59";
      return `${datePortion}T${timePortion}`;
    }
  }
  getTransactionData() {
    console.log(this.searchField.dateFrom)
    const startDate =this.dataTimeValueST(this.searchField.dateFrom)// "2024-04-27T00:00:00";
    const endTime = this.dataTimeValueET(this.searchField.dateTill)//"2024-04-27T23:59:59";

    this.transactionservice.getTransactionsv(startDate, endTime).subscribe(data => {
      if (data) {
        console.log(data);
        this.datalist =data;
      }
    });
  }



/*  getDatalist() {
    const params = {};
    this.transactionservice
      .getTransactions(this.searchField, this.page, this.sort)
      .subscribe(data => {
        if (data["success"]) {
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        }
      });
  }*/

  selectData(tran: any) {
    //this.data = new TransactionModel();
    this.sideNave.open();
    this.datacarddetail=tran;
    console.log( this.datacarddetail)
  }

  /*selectData(tran: any) {
    this.data = new TransactionModel();
    this.sideNave.open();
    if (tran.id) {
      this.id = tran.id;
      this.transactionservice.getTransaction(tran.id).subscribe(
        data => {
          if (data["success"]) {
            this.data = data["result"];
            if (this.data.cardDetail) {
              this.datacarddetail = this.data.cardDetail;
            } else {
              this.datacarddetail = new CardDetailModel();
            }
            if (this.data.merchant) {
              this.datamerchant = this.data.merchant;
            } else {
              this.datamerchant = new MerchantModel();
            }
          } else {
            this.data = new TransactionModel();
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            objmsg.title = "Error";
            objmsg.message =
              "Error response from server while fetching transaction data!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          this.data = new TransactionModel();
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Error in fetching transaction data!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }*/

  getDateConfig() {
    const date = new Date("01-Jan-1900");
    return this.tools.getBsDatePickerConfig(true, date);
  }

  isAllowExport() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>export");
    return isAdd;
  }

  onExcelClick() {
    this.getDataForExportToExcel();
  }

  getDataForExportToExcel(): void {
    if (!this.datalist || this.datalist.length === 0) {
      alert('No data available to export.');
      return;
    }
  
    const data = this.datalist;
  
    // Extract headers from the first object
    const headers = Object.keys(data[0]);
    
    // Add headers to the CSV
    const csvRows = [headers.join(',')]; // Column headers
  
    // Add data rows with formatted date and time
    for (const row of data) {
      const values = headers.map(header => {
        if (header === 'tranDate' && row[header]) {
          // Format the date (e.g., 21-Jan-2025 14:16:30)
          const date = new Date(row[header]);
          return `"${date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })} ${date.toLocaleTimeString('en-GB')}"`;
        } else {
          const escapedValue = String(row[header] || '').replace(/"/g, '""'); // Escape double quotes
          return `"${escapedValue}"`;
        }
      });
      csvRows.push(values.join(',')); // Add row to CSV
    }
  
    // Combine all rows into a single CSV string
    const csvContent = csvRows.join('\n');
  
    // Create a Blob for the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Transaction_Report.csv';
    link.click();
  
    // Clean up the URL
    window.URL.revokeObjectURL(url);
  }
  
  
  
  //   getDataForExportToExcel() {
  //   let page: GridPage = new GridPage();
  //   page.size = 1000;
  //   page.offset = 1;
  //   let sort: SortClass = new SortClass();
  //   this.transactionservice
  //     .getTransactions(this.searchField, page, this.sort)
  //     .subscribe(
  //       data => {
  //         let obj: Array<TransactionModel>;
  //         if (data["success"]) {
  //           obj = data["result"];
  //           this.csvservice.exportToCsv(
  //             obj,
  //             this.gridcols,
  //             undefined,
  //             "transaction"
  //           );
  //         } else {
  //           alert("Failed to get transaction list!");
  //         }
  //       },
  //       error => {
  //         alert("Failed to get transaction list!");
  //       }
  //     );
  // }
  
  dataTimeValue = (val: Date) => {
    if (val) {
      return this.tools.formatDateToDDMMMYYHHMMSS(val);
    }
  }
  
}
